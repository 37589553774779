import req from "../methods/req"
import method from '../methods/method'

// 商品销售额、区域销售情况、区域终端数量、30天在线人数
export const BiIndex = function (data) {
	return req({
		url: '/manage/crm.bi/index',
		...data,
	})
}
export const realtime = function (data) {
	return req({
		url: '/manage/crm.bi/realtime',
		...data,
	})
}

// 终端
export const visitChart = function (data) {
	return req({
		url: '/manage/crm.bi/visitChart',
		...data,
	})
}

export const visitCoverChart = function (data) {
	return req({
		url: '/manage/crm.bi/visitCoverChart',
		...data,
	})
}

export const location = function (data) {
	return req({
		url: '/manage/crm.bi/location',
		...data,
	})
}


// manage/crm.bi/index                       商品销售额、区域销售情况、区域终端数量、30天在线人数
// manage/crm.bi/realtime                       实时更新的数据
// manage/crm.bi/visitChart                     终端拜访次数
// manage/crm.bi/visitCoverChart           终端拜访覆盖率